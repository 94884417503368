import { translationService } from './../services/translationsService';
import moment from 'moment';
import { Scope } from '../types/dto/Scope';
import { stringExtensions } from './stringExtensions';
import { fixed } from '../environments/staticEnvironment';
import { Cookies } from 'react-cookie';
import { ZimHCaptchaProps } from '../types/dto/ZimHCaptchaProps';

interface IOption {
  id: string;
  label: string;
  type: string;
}

class AppHelper {
  private _isRtl: boolean | null;
  private _isGlobalPageRtl: boolean | null;
  private regex = new RegExp('^[a-zA-Z]{4}[0-9]{6,7}$');
  private _consNumberPattern = new RegExp('^[a-zA-Z]{3}[a-zA-Z0-9]{3,13}[0-9]{4}$');
  private regexGlobalPrintStorageCertificate = new RegExp('^[a-zA-Z]{4}[0-9]{7}$');
  private regexIndiaPrintStorageCertificate = new RegExp('^(ZIMU|zimu)[a-zA-Z]{3}[0-9]{1,10}$');
  private _emailPattern = new RegExp('^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$');
  private _digitPattern = new RegExp('^\\d$');
  private _siteKey: string;
  private _useCaptcha: string | null;
  private _captchaSingleMode: string | null;
  private _enableCaptcha: boolean | null;
  private _lessCaptcha: boolean | null;
  private _lessSiteKey: string;
  private _recentSearchCount: number | null;
  private _lessPattern = '_less';
  private greyOverlayEl = document.querySelector<HTMLElement>('.GreyOverlay');
  private _newContacts: string | null;
  private _blRegex = new RegExp('^(ZIMU|OCEU|zimu|oceu)[a-zA-Z]{3}[0-9]{1,9}$');
  private _contRegex = new RegExp('^[a-zA-Z]{4}[0-9]{7}$');

  testContainerId = (containerId: string) => {
    return this.regex.test(containerId);
  };

  testBLNumber = (blNumber: string) => {
    return this._consNumberPattern.test(blNumber);
  };

  testGlobalPrintStorageCertificate = (containerId: string) => {
    return this.regexGlobalPrintStorageCertificate.test(containerId);
  };

  testIndiaPrintStorageCertificate = (containerId: string) => {
    return this.regexIndiaPrintStorageCertificate.test(containerId);
  };

  get isRtl() {
    if (!this._isRtl) {
      this._isRtl = document.querySelector('html')?.getAttribute('dir') === 'rtl';
    }

    return this._isRtl;
  }

  get isGlobalPageRtl() {
    if (!this._isGlobalPageRtl) {
      this._isGlobalPageRtl = document.querySelector('#main')?.getAttribute('dir') === 'rtl';
    }

    return this._isGlobalPageRtl;
  }

  getDefaultScope = <T>(): Scope<T> => ({
    isLoading: false,
    result: null,
    isResponse: false,
    errorMessage: ''
  });

  minDate = () => {
    return new Date(-8640000000000000);
  };

  getLastUrlSegment = (): string => {
    const segments = window.location.href.split('/');

    return segments.pop() || segments.pop();
  };

  getPageTitle = () => {
    return (
      document.querySelector('div[data-page-title]')?.getAttribute('data-page-title') ??
      document.querySelector('html[data-page-title]')?.getAttribute('data-page-title')
    );
  };

  getConsNumberPattern = () => {
    return this._consNumberPattern;
  };

  getDigitPattern = () => {
    return this._digitPattern;
  };

  getBlPattern = () => {
    return this._blRegex;
  };

  getContPattern = () => {
    return this._contRegex;
  };

  private isMarine = (option: any, query?: string) =>
    query
      ? option?.label.trim().toLowerCase().startsWith(query.toLowerCase()) &&
        option?.type?.toLowerCase().includes('marine')
      : option?.type?.toLowerCase().includes('marine');

  sortByFirstQuery = (options: any[], query?: string) => {
    options = options.sort((prev, next) => {
      const prevIsMarine = this.isMarine(prev, query);
      const nextIsMarine = this.isMarine(next, query);

      if (prevIsMarine && nextIsMarine) {
        return 0;
      }

      if (prevIsMarine) {
        return -1;
      }

      return 1;
    });
  };

  getDomainWithoutSubdomain = () => {
    const urlParts = window.location.hostname.split('.');

    return urlParts
      .slice(0)
      .slice(-(urlParts.length === 4 ? 3 : 2))
      .join('.');
  };

  getEmailPattern = () => {
    return this._emailPattern;
  };

  toFirstLowerCase = (str: string) => {
    if (stringExtensions.isEmptyOrSpaces(str)) {
      return str;
    }

    const firstLetter = str[0].toLowerCase();

    return firstLetter + str.slice(1);
  };

  removeGMTfromDate = (date: string) => {
    if (stringExtensions.isEmptyOrSpaces(date)) {
      return date;
    }

    if (date.includes('Z')) {
      return date.split('Z')[0];
    }

    return date.split('+')[0];
  };

  checkIfUsPort = (query: string) => {
    if (query && query.trim().slice(0, 2) == 'US') return true;
    return false;
  };

  checkIfTimeIsZero = (date: string) => {
    let isZero = true;
    if (date) {
      let time: string[] = [];
      if (date.indexOf('T') > 0) {
        time = date.split('T')[1].split('+')[0].split(':');
      } else if (date.indexOf(':') > 0) {
        time = date.split(' ')[1].split(':');
      }

      time.forEach((item) => {
        if (item !== '00') {
          isZero = false;
        }
      });
    }
    return isZero;
  };

  formatDate = (query: string, isUs = false) => {
    const date = this.removeGMTfromDate(query);
    if (isUs) {
      return moment(date).format(`DD-MMM-YYYY ${this.checkIfTimeIsZero(date) ? '' : 'H:mm'}`);
    } else {
      return moment(date).format('DD-MMM-YYYY H:mm');
    }
  };

  formatDateV2 = (query: string, isUs = false) => {
    if (!query || isNaN(Date.parse(query))) {
      return query;
    }
    const date = this.removeGMTfromDate(query);
    if (isUs) {
      return moment(date).format('DD-MMM-YYYY H:mm A');
    } else {
      return moment(date).format('DD-MMM-YYYY H:mm');
    }
  };

  getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };

  getSiteKey = () => {
    if (!this._siteKey) {
      const metaElem = document.querySelectorAll('[name="site-key"]');
      if (metaElem && metaElem.length > 0) {
        this._siteKey = metaElem[0].getAttribute('content');
      } else {
        this._siteKey = '';
      }
    }
    return this._siteKey;
  };
  useCaptcha = (): boolean => {
    if (!this._useCaptcha) {
      const metaElem = document.querySelectorAll('[name="captcha"]');
      this._useCaptcha =
        metaElem != null && metaElem.length > 0 ? metaElem[0].getAttribute('content') : '0';
    }
    return this._useCaptcha === '1';
  };
  captchaSingleMode = (): boolean => {
    if (!this._captchaSingleMode) {
      const metaElem = document.querySelectorAll('[name="captcha-single-mode"]');
      this._captchaSingleMode =
        metaElem != null && metaElem.length > 0 ? metaElem[0].getAttribute('content') : '0';
    }
    return this._captchaSingleMode === '1';
  };

  roeDataFormatter = (date: string) => {
    if (stringExtensions.isEmptyOrSpaces(date)) return '';
    if (date.split('.').length === 3) return date;
    return moment(date.split('+')[0]).format('DD.MM.YYYY');
  };

  getUsersCountry = () => {
    if (process.env.NODE_ENV === 'production') {
      const jUsersCountry = document.querySelector('#main').getAttribute('data-users-country')
        ? JSON.parse(document.querySelector('#main').getAttribute('data-users-country'))
        : null;
      return jUsersCountry;
    } else {
      return null;
    }
  };

  addMetaTag = (name: string, content: string) => {
    const meta = document.createElement('meta');
    meta.httpEquiv = name;
    meta.content = content;
    document.getElementsByTagName('head')[0].appendChild(meta);
  };

  PromiseHelperAllSettled = (promises) => {
    return Promise.all(
      promises.map(function (promise) {
        return promise
          .then(function (value) {
            return { state: 'fulfilled', value: value };
          })
          .catch(function (reason) {
            return { state: 'rejected', reason: reason };
          });
      })
    );
  };

  getOnlyDate = (date: string) => {
    if (date) {
      const dateObject = new Date(this.removeGMTfromDate(date));
      const resultDate = `${
        (dateObject.getDate() < 10 ? '0' : '') + dateObject.getDate()
      }-${dateObject.toLocaleString(translationService.getCulture(), {
        month: 'short'
      })}-${dateObject.getFullYear()}`;
      return resultDate;
    }
    return '';
  };

  getOnlyDateNow = () => {
    const dateObject = new Date();
    const month = dateObject.toLocaleString(translationService.getCulture(), {
      month: 'numeric'
    });
    const resultDate = `${(dateObject.getDate() < 10 ? '0' : '') + dateObject.getDate()}-${
      (parseInt(month) < 10 ? '0' : '') + month
    }-${dateObject.getFullYear()}`;
    return resultDate;
  };

  getOnlyTime = (date: string) => {
    if (date) {
      const dateObject = new Date(this.removeGMTfromDate(date));
      const resultTime = `${dateObject.getHours()}:${
        (dateObject.getMinutes() < 10 ? '0' : '') + dateObject.getMinutes()
      }`;
      return resultTime;
    }
    return '';
  };

  get geEnableCaptcha() {
    if (!this._enableCaptcha) {
      this._enableCaptcha =
        document.querySelector('html')?.getAttribute('data-enable-captcha') === 'True';
    }

    return this._enableCaptcha;
  }
  get geLessCaptcha() {
    if (!this._lessCaptcha) {
      this._lessCaptcha =
        document.querySelector('html')?.getAttribute('data-less-captcha') === 'True';
    }

    return this._lessCaptcha;
  }
  getLessSiteKey = () => {
    if (!this._lessSiteKey) {
      const metaElem = document.querySelectorAll('[name="less-site-key"]');
      if (metaElem && metaElem.length > 0) {
        this._lessSiteKey = metaElem[0].getAttribute('content');
      } else {
        this._lessSiteKey = '';
      }
    }
    return this._lessSiteKey;
  };
  get getRecentSearchCount() {
    if (!this._recentSearchCount) {
      this._recentSearchCount = parseInt(
        document.querySelector('html')?.getAttribute('data-recent-search-count')
      );
      if (isNaN(this._recentSearchCount)) {
        //setup default value
        this._recentSearchCount = 10;
      }
    }

    return this._recentSearchCount;
  }
  getLessPattern = () => {
    return this._lessPattern;
  };

  cookiesExpiryDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    return date;
  };

  addScript = (url: string, async = false) => {
    const script = document.createElement('script');

    script.src = url;
    script.async = async;

    document.body.appendChild(script);
  };

  greyOverlayToggle = (toggle: boolean) => {
    if (this.greyOverlayEl) {
      setTimeout(() => {
        this.greyOverlayEl.style.display = toggle ? 'block' : 'none';
      }, 300);
    }
  };

  getContactsUrl = (isMenu?: boolean): string => {
    if (!this._newContacts) {
      const metaElem = document.querySelectorAll('[name="new-contacts"]');
      this._newContacts =
        metaElem != null && metaElem.length > 0 ? metaElem[0].getAttribute('content') : '0';
    }
    let newContactsUrl = fixed.contactUs.newContacts;
    const cookies = new Cookies();
    const countryName = cookies.get('countryName')?.replaceAll('.', '');
    newContactsUrl = isMenu ? `${newContactsUrl}/${countryName}` : newContactsUrl;
    return this._newContacts === '1' ? newContactsUrl : '';
  };

  getCaptchaParams = (
    culture: string,
    innerWidth: number,
    setToken: (token: string, ekey: string) => any,
    lessCaptcha?: boolean,
    captchaId?: string
  ): ZimHCaptchaProps => {
    const captchaParams: ZimHCaptchaProps = {
      sitekey: lessCaptcha ? appHelper.getLessSiteKey() : appHelper.getSiteKey(),
      size: lessCaptcha ? 'invisible' : innerWidth <= 425 ? 'compact' : 'normal',
      id: captchaId ?? 'ZimCaptchaId',
      languageOverride: culture ?? undefined,
      onVerify: setToken
    };
    const url = new URL(window.location.href);
    if (url.host === fixed.zimChina) {
      captchaParams.endpoint = fixed.hCaptcha.endPoint;
      captchaParams.assethost = fixed.hCaptcha.assetHost;
      captchaParams.imghost = fixed.hCaptcha.imgHost;
      captchaParams.reportapi = fixed.hCaptcha.reportApi;
      captchaParams.apihost = fixed.hCaptcha.apiHost;
    }
    return captchaParams;
  };

  isObjectEmpty = (objectName) => {
    return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
  };
}

export const appHelper = new AppHelper();
